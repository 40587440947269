import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  DarkModeContext,
  getRequiredDateFormat,
  AlarmBullet,
  ArrowOutwardIcon,
  BasicCard,
} from "@datwyler/shared-components";

const WidgetTable = (props) => {
  const { alarms, tableName } = props;
  const navigate = useNavigate();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const location = useLocation();

  const fontStyles = {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "0.02px",
    lineHeight: "16px",
  };

  const handleClickArrow = () => {
    navigate("/alarm", { state: location.state });
  };

  return (
    <BasicCard
      sx={{
        padding: "0px",
        width: "100%",
        height: "230px",
        color: colors.fontGrey,
        fontFamily: "NotoSans-Medium",
        boxShadow: "unset",
        borderRadius: "8px",
      }}
    >
      <Box sx={{ paddingLeft: "16px", paddingTop: "16px" }}>
        <Box>
          <Typography
            sx={{
              fontSize: 16,
              color: colors.widgetTableTitle,
              fontWeight: 500,
              letterSpacing: "0.02px",
              lineHeight: "24px",
              display: "inline-flex",
            }}
          >
            {intl.formatMessage({ id: tableName })}
          </Typography>
          <IconButton
            onClick={handleClickArrow}
            sx={{
              display: "inline-flex",
              float: "right",
              marginRight: "8px",
              marginTop: "-8px",
            }}
          >
            <ArrowOutwardIcon fill={colors.iconColor} />
          </IconButton>
        </Box>
        <Box
          sx={{
            paddingTop: "16px",
            paddingBottom: "24px",
            height: "178px",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Grid
              container
              sx={{
                color: colors.widgetTableHeader,
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: "0.02px",
                lineHeight: "16px",
              }}
            >
              <Grid item xs={5}>
                <Typography>{intl.formatMessage({ id: "site" })}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{intl.formatMessage({ id: "alert" })}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{intl.formatMessage({ id: "date" })}</Typography>
              </Grid>
            </Grid>
            <Box sx={{ height: "154px" }}>
              {alarms.length < 1 && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 16, color: colors.widgetTableHeader }}
                  >
                    {intl.formatMessage({ id: "no_alarm" })}
                  </Typography>
                </Box>
              )}
              {alarms.map((alarm, index) => {
                return (
                  <Grid
                    key={"alarm_" + index}
                    container
                    sx={{
                      paddingTop: "16px",
                    }}
                  >
                    <Grid
                      item
                      xs={5}
                      sx={{ alignItems: "start", display: "flex" }}
                    >
                      <AlarmBullet
                        severity={alarm.severity}
                        width={7}
                        height={7}
                      />
                      <Typography
                        sx={{
                          ...fontStyles,
                          display: "inline-flex",
                          color: colors.widgetTableFontSite,
                        }}
                      >
                        {alarm.device.site.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        sx={{
                          ...fontStyles,
                          color: colors.widgetTableFont,
                        }}
                      >
                        {alarm.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{
                          ...fontStyles,
                          color: colors.widgetTableFont,
                        }}
                      >
                        {getRequiredDateFormat(
                          parseInt(alarm.time),
                          "DD-MM-YYYY"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </BasicCard>
  );
};

export default WidgetTable;
