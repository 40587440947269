import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { DarkModeContext, Map, BasicCard } from "@datwyler/shared-components";

const GeographicMap = (props) => {
  const { mapProps, locations } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  return (
    <BasicCard
      sx={{
        padding: "0px",
        width: "100%",
        fontFamily: "NotoSans-Medium",
        fontWeight: 500,
        boxShadow: "unset",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{ paddingLeft: "16px", paddingTop: "16px", paddingBottom: "10px" }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            color: colors.geographicMapTitle,
          }}
        >
          {intl.formatMessage({ id: "geographic_map" })}
        </Typography>
      </Box>
      <Map
        locations={locations}
        mapProps={mapProps}
        setSelectedLocation={() => {}}
        height={"438px"}
      />
    </BasicCard>
  );
};

export default GeographicMap;
