import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AddWidgetButton from "./AddWidgetButton";
import AddWidgetDialog from "./AddWidgetDialog";
import DeleteWidgetDialog from "./DeleteWidgetDialog";
import { PADDINGLEFT } from "../../constants";
import Widget from "./Widget";
import {
  SwipeableCarouselSlider,
  getContentWidth,
  useNavBarWidthListener,
  useScrollBarListener,
  useResizeListener,
} from "@datwyler/shared-components";

const Widgets = (props) => {
  const {
    widgets,
    updateTenant,
    tenant,
    updateTenantResponseData,
    resetUpdateTenantData,
    refetchTenantsById,
  } = props;
  const [isAddWidgetDialogOpen, setIsAddWidgetDialogOpen] = useState(false);
  const [widgetToBeDeleted, setWidgetToBeDeleted] = useState(null);
  const [maxSwipes, setMaxSwipes] = useState(0);
  const ADDWIDGETWIDTH = 144;
  const SLIDERMARGINLEFT = 24;

  useEffect(() => {
    if (widgets?.length > 0) {
      setMaxSwipes(Math.floor(widgets.length / 5));
    } else {
      setMaxSwipes(0);
    }
  }, [widgets]);

  const getSliderWidth = () => {
    return getContentWidth() - ADDWIDGETWIDTH - PADDINGLEFT - SLIDERMARGINLEFT;
  };

  const [sliderWidth, setSliderWidth] = useState(getSliderWidth());
  useNavBarWidthListener(() => setSliderWidth(getSliderWidth()));
  useScrollBarListener(() => setSliderWidth(getSliderWidth()));
  useResizeListener(() => setSliderWidth(getSliderWidth()));

  const handleAddWidget = () => {
    setIsAddWidgetDialogOpen(true);
  };

  const handleDeleteWidget = () => {
    const tempWidgets = widgets
      .filter((w) => w.type !== widgetToBeDeleted.type)
      .map((mapWidget) => mapWidget.type);
    const submitValues = {
      id: tenant.id,
      name: tenant.name,
      status: tenant.status,
      managed: tenant.managed,
      dashboard: { widgets: tempWidgets },
    };
    updateTenant({
      variables: { input: submitValues },
    });
  };

  return (
    <Box style={{ display: "flex", width: "100%" }}>
      <Box
        style={{
          display: "inline-flex",
          alignItems: "end",
          marginBottom: 8,
          paddingTop: maxSwipes < 1 ? 0 : 46,
        }}
      >
        <AddWidgetButton handleAddWidget={handleAddWidget} />
      </Box>
      <Box
        style={{
          display: "inline-block",
          width: sliderWidth,
          marginLeft: SLIDERMARGINLEFT,
        }}
      >
        <SwipeableCarouselSlider maxSwipes={maxSwipes}>
          {widgets.map((widget, index) => (
            <Widget
              key={index}
              widget={widget}
              index={index}
              setWidgetToBeDeleted={setWidgetToBeDeleted}
            />
          ))}
        </SwipeableCarouselSlider>
      </Box>
      <AddWidgetDialog
        isOpen={isAddWidgetDialogOpen}
        setIsOpen={setIsAddWidgetDialogOpen}
        updateTenant={updateTenant}
        tenant={tenant}
        updateTenantResponseData={updateTenantResponseData}
        resetUpdateTenantData={resetUpdateTenantData}
        refetchTenantsById={refetchTenantsById}
      />
      <DeleteWidgetDialog
        isOpen={!!widgetToBeDeleted}
        handleDeleteWidget={handleDeleteWidget}
        updateTenantResponseData={updateTenantResponseData}
        setWidgetToBeDeleted={setWidgetToBeDeleted}
        resetUpdateTenantData={resetUpdateTenantData}
        refetchTenantsById={refetchTenantsById}
        tenant={tenant}
      />
    </Box>
  );
};

export default Widgets;
