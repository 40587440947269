import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getRequiredDateFormat } from "@datwyler/shared-components";
import { Fragment } from "react";

const CustomTooltip = (props) => {
  const { slice, temperatureData, humidityData } = props;
  const { points } = slice;

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ backgroundColor: "#EAECF0" }}>
        <Box sx={{ position: "relative", height: "100%", padding: "8px" }}>
          <Typography
            key={points[0].serieId + "time"}
            sx={{ fontFamily: "NotoSans-Regular", fontSize: 10 }}
          >
            {getRequiredDateFormat(points[0]?.data?.x, "DD/MM/YY HH:mm:ss")}
          </Typography>
          {points?.map((point, index) => {
            return (
              <Fragment key={index}>
                {temperatureData[0]?.data[point.index]?.y && (
                  <Typography
                    key={temperatureData[0]?.id + index}
                    sx={{ fontFamily: "NotoSans-Regular", fontSize: 10 }}
                  >
                    {temperatureData[0]?.id +
                      ": " +
                      temperatureData[0]?.data[point.index]?.y}
                  </Typography>
                )}
                {humidityData[0]?.data[point.index]?.y && (
                  <Typography
                    key={humidityData[0]?.id + index}
                    sx={{ fontFamily: "NotoSans-Regular", fontSize: 10 }}
                  >
                    {humidityData[0]?.id +
                      ": " +
                      humidityData[0]?.data[point.index]?.y}
                  </Typography>
                )}
              </Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomTooltip;
