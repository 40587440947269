import { useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { getWidgetDetails, getIconComponent } from "./utils";
import Slide from "@mui/material/Slide";
import {
  CRITICAL_ALARM,
  WATER_LEAK,
  PDU_ALARM,
  ONLINE_DEVICES,
  DOOR_ALARM,
  SMOKE_ALARM,
} from "../../constants";
import {
  CloseIcon,
  CarouselItem,
  DarkModeContext,
} from "@datwyler/shared-components";

const Widget = (props) => {
  const { widget, index, setWidgetToBeDeleted } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const getName = () => {
    switch (widget?.type) {
      case ONLINE_DEVICES:
        return intl.formatMessage({ id: "online_devices" });
      case CRITICAL_ALARM:
        return intl.formatMessage({ id: "critical_alarm" });
      case WATER_LEAK:
        return intl.formatMessage({ id: "water_leak" });
      case PDU_ALARM:
        return intl.formatMessage({ id: "pdu_alarm" });
      case DOOR_ALARM:
        return intl.formatMessage({ id: "door_alarm" });
      case SMOKE_ALARM:
        return intl.formatMessage({ id: "smoke_alarm" });
      default:
        return "";
    }
  };

  const handleClickDeleteWidget = (widget) => {
    setWidgetToBeDeleted(widget);
  };

  return (
    <CarouselItem style={{ marginRight: 24 }} key={"widget_" + index}>
      <Slide direction="down" in={!!widget} mountOnEnter unmountOnExit>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            color: colors.widgetFont,
            backgroundColor: colors.widgetBg,
            boxShadow: "unset",
            borderRadius: "8px",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              {getIconComponent(widget.type, colors)}
              <Box
                onClick={() => handleClickDeleteWidget(widget)}
                style={{
                  display: "inline-flex",
                  float: "right",
                  padding: "12px 12px 0px 12px",
                  cursor: "pointer",
                }}
              >
                <IconButton>
                  <CloseIcon fill={colors.iconColor} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{}}>
              <Box
                style={{
                  display: "inline-flex",
                  paddingLeft: "12px",
                  paddingTop: "22px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "NotoSans-Regular",
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  {getName()}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "inline-flex",
                  float: "right",
                  paddingRight: 12,
                  paddingTop: 4,
                }}
              >
                {getWidgetDetails(widget.type, widget.details)}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Slide>
    </CarouselItem>
  );
};

export default Widget;
