export const PADDINGLEFT = 24;

export const ACTIVE = "ACTIVE";
export const CRITICAL = "CRITICAL";
export const COOLING_SYSTEM = "COOLING_SYSTEM";
export const UPS = "UPS";

export const CRITICAL_ALARM = "CRITICAL_ALARM";
export const WATER_LEAK = "WATER_LEAK";
export const PDU_ALARM = "PDU";
export const ONLINE_DEVICES = "ONLINE_DEVICES";
export const DOOR_ALARM = "DOOR_CONTACT";
export const SMOKE_ALARM = "SMOKE_DETECTOR";

export const GATEWAY = "GATEWAY";
export const TEMP_HUMIDITY = "TEMP_HUMIDITY";
export const MODEM_TEMPERATURE = "modem_temperature";
export const TEMPERATURE = "temperature";
export const HUMIDITY = "humidity";
