import { useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  CRITICAL_ALARM,
  WATER_LEAK,
  PDU_ALARM,
  ONLINE_DEVICES,
  DOOR_ALARM,
  SMOKE_ALARM,
} from "../../constants";
import {
  CloseIcon24p,
  BasicSelectWithCheckMark,
  BasicButton,
  useSnackbar,
  DarkModeContext,
} from "@datwyler/shared-components";

let isSubmitting = false;

const AddWidgetDialog = (props) => {
  const {
    isOpen = false,
    setIsOpen,
    updateTenant,
    tenant,
    updateTenantResponseData,
    resetUpdateTenantData,
    refetchTenantsById,
  } = props;
  const intl = useIntl();
  const form = useForm({
    defaultValues: {
      type: null,
    },
  });
  const type = form.watch("type");
  const { enqueueSnackbar } = useSnackbar();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (updateTenantResponseData?.updateTenant?.tenant && isSubmitting) {
      isSubmitting = false;
      form.reset();
      refetchTenantsById(tenant.id);
      resetUpdateTenantData();
      enqueueSnackbar(intl.formatMessage({ id: "add_widget_success" }), {
        variant: "info",
      });
    }
  }, [updateTenantResponseData]);

  const widgetTypeOptions = [
    {
      priority: 1,
      label: intl.formatMessage({ id: "critical_alarm" }),
      value: CRITICAL_ALARM,
    },
    {
      priority: 2,
      label: intl.formatMessage({ id: "water_leak" }),
      value: WATER_LEAK,
    },
    {
      priority: 3,
      label: intl.formatMessage({ id: "pdu_alarm" }),
      value: PDU_ALARM,
    },
    {
      priority: 4,
      label: intl.formatMessage({ id: "online_devices" }),
      value: ONLINE_DEVICES,
    },
    {
      priority: 5,
      label: intl.formatMessage({ id: "door_alarm" }),
      value: DOOR_ALARM,
    },
    {
      priority: 6,
      label: intl.formatMessage({ id: "smoke_alarm" }),
      value: SMOKE_ALARM,
    },
  ];

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAddWidget = () => {
    if (!tenant) {
      enqueueSnackbar(intl.formatMessage({ id: "error_save_widget" }), {
        variant: "warning",
      });
    }
    const newWidget = form.getValues("type");
    const currentWidgets = tenant?.dashboard?.widgets
      ? [...tenant.dashboard.widgets]
      : [];

    if (currentWidgets.includes(newWidget)) {
      enqueueSnackbar(intl.formatMessage({ id: "error_widget_exists" }), {
        variant: "warning",
      });
      return;
    }
    currentWidgets.push(newWidget);
    const submitValues = {
      id: tenant.id,
      name: tenant.name,
      status: tenant.status,
      managed: tenant.managed,
      dashboard: { widgets: currentWidgets },
    };

    isSubmitting = true;
    updateTenant({
      variables: { input: submitValues },
    });

    handleClose();
  };

  const isSaveDisabled = !type;

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          height: "212px",
          marginTop: 0,
          backgroundColor: colors.cardBg,
        },
      }}
      sx={{
        "&& .MuiDialog-container": {
          alignItems: "unset",
          marginTop: "145px",
        },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "24px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "add_widget" })}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "24px" }}>
        <Box sx={{ fontSize: 14, width: 376, paddingTop: "4px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BasicSelectWithCheckMark
                menuItems={widgetTypeOptions}
                form={form}
                formItemLabel={"type"}
                label={intl.formatMessage({ id: "select_alert_type" })}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "validation_required" }),
                  },
                }}
                SelectDisplayProps={{
                  paddingTop: "13px",
                  paddingBottom: "12px",
                  paddingLeft: "16px",
                  height: "19px",
                }}
                inputLabelSx={{
                  fontFamily: "NotoSans-Regular",
                  fontSize: "16px",
                  letterSpacing: "0.01px",
                  lineHeight: "24px",
                  top: "2px",
                }}
                menuPaperSx={{
                  width: "376px",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          paddingBottom: "24px",
          paddingRight: "24px",
          paddingTop: 0,
        }}
      >
        <Box>
          <BasicButton
            onClick={handleAddWidget}
            variant={"contained"}
            disabled={isSaveDisabled}
          >
            {intl.formatMessage({ id: "add_widget" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddWidgetDialog;
