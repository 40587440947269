import { useState, useEffect, useContext, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import CustomTooltip from "./CustomTooltip";
import { generateTemperatureHumidityData } from "./utils";
import CustomChartFooter from "./CustomChartFooter";
import {
  ACTIVE,
  GATEWAY,
  TEMP_HUMIDITY,
  MODEM_TEMPERATURE,
  TEMPERATURE,
  HUMIDITY,
} from "../../constants";
import {
  BasicLineChart,
  DarkModeContext,
  CHARTDEFAULTS,
  BasicSelectWithoutForm,
  useDevice,
  useTelemetry,
  useLoadingGif,
  TenantIdContext,
  BasicCard,
  moment,
} from "@datwyler/shared-components";

let fetchTelemetriesInterval;

const availableAttributes = [MODEM_TEMPERATURE, TEMPERATURE, HUMIDITY];

const availableDevices = [TEMP_HUMIDITY, GATEWAY];

const TemperatureHumidityChart = (props) => {
  const { locations } = props;
  const intl = useIntl();
  const [siteOptions, setSiteOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [selectedSiteChart, setSelectedSiteChart] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [temperatureData, setTemperatureData] = useState([]);
  const [humidityData, setHumidityData] = useState([{ data: [] }]);
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const { tenantId }: any = useContext(TenantIdContext);
  const { isFetchLoading, fetchDeviceData, fetchDevices } = useDevice();
  const { fetchTelemetryData, fetchTelemetry } = useTelemetry();
  const { colors }: any = useContext(DarkModeContext);
  const [timePeriod, setTimePeriod] = useState("hour");
  const timePeriodOptions = useMemo(() => {
    return [
      {
        label: "hour",
        value: "hour",
        priority: 1,
      },
      {
        label: "day",
        value: "day",
        priority: 2,
      },
      {
        label: "week",
        value: "week",
        priority: 3,
      },
    ];
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(fetchTelemetriesInterval);
    };
  }, []);

  useEffect(() => {
    let sites = [];
    const siteOptions = [];
    locations.forEach((loc) => {
      if (loc?.sites && loc?.status === ACTIVE) {
        sites = sites.concat(loc.sites);
      }
    });

    sites.forEach((site, index) => {
      if (site?.status === ACTIVE) {
        siteOptions.push({
          priority: index,
          label: site.name,
          value: site.id,
        });
      }
    });

    setSiteOptions(siteOptions);
    setDeviceOptions([]);
  }, [locations]);

  useEffect(() => {
    if (selectedSiteChart) {
      fetchDevices({
        variables: {
          tenantId: tenantId,
          filter: [`siteId:${selectedSiteChart}`],
          page: { number: 0, size: 999999 },
        },
      });
    }
  }, [selectedSiteChart]);

  useEffect(() => {
    if (fetchDeviceData) {
      const deviceOptions = [];
      fetchDeviceData?.devices?.devices?.forEach((dev, index) => {
        const isAvailable = availableDevices.includes(dev.type);
        if (dev?.status === ACTIVE && isAvailable) {
          deviceOptions.push({
            priority: index,
            label: dev.name,
            value: dev.id,
          });
        }
      });
      setDeviceOptions(deviceOptions || []);
    }
  }, [fetchDeviceData]);

  useEffect(() => {
    if (fetchTelemetryData) {
      const data = fetchTelemetryData?.telemetries?.telemetries || [];
      setTemperatureData(generateTemperatureHumidityData(data, "temperature"));
      setHumidityData(generateTemperatureHumidityData(data, "humidity"));
    }
  }, [fetchTelemetryData]);

  useEffect(() => {
    setIsLoading(isFetchLoading || false);
  }, [isFetchLoading]);

  useEffect(() => {
    if (siteOptions[0]) {
      setSelectedSiteChart(siteOptions[0].value);
    }
  }, [siteOptions]);

  useEffect(() => {
    if (deviceOptions[0]) {
      setSelectedDevice(deviceOptions[0].value);
    }
  }, [deviceOptions]);

  useEffect(() => {
    if (selectedDevice) {
      const deviceList = [selectedDevice];
      intervalFetchTelemetryData(deviceList, tenantId);

      // fetch data every 15sec
      clearInterval(fetchTelemetriesInterval);
      fetchTelemetriesInterval = setInterval(
        () => intervalFetchTelemetryData(deviceList, tenantId),
        15000
      );
    }
  }, [selectedDevice, tenantId, timePeriod]);

  const intervalFetchTelemetryData = (deviceList, tenantId) => {
    const startTime = moment().subtract(1, timePeriod).unix();
    let filtersToSend = "";
    availableAttributes.forEach((attribute, index) => {
      if (index === 0) filtersToSend = `name:${attribute}`;
      else filtersToSend = filtersToSend + `|name:${attribute}`;
    });

    fetchTelemetry({
      variables: {
        tenantId: tenantId,
        startTime: startTime,
        deviceIds: deviceList,
        page: { number: 0, size: 999999 }, // set a huge page size
        sort: ["time,asc"],
        filter: [filtersToSend],
      },
    });
  };

  const axisLeft = {
    ...CHARTDEFAULTS.axisLeft,
    legend: "",
    tickValues: [
      "0",
      "10",
      "20",
      "30",
      "40",
      "50",
      "60",
      "70",
      "80",
      "90",
      "100",
    ],
  };

  const axisRight = {
    ...CHARTDEFAULTS.axisRight,
    legend: "",
    tickValues: [
      "0",
      "10",
      "20",
      "30",
      "40",
      "50",
      "60",
      "70",
      "80",
      "90",
      "100",
    ],
  };

  const gridYValues = axisLeft.tickValues;
  const gridYValues2 = axisRight.tickValues;

  const y2Scale = {
    type: "linear",
    min: 0,
    max: 100,
  };

  const yScale = {
    type: "linear",
    min: 0,
    max: 100,
  };

  const chartMargin = { top: 26, right: 32, bottom: 13, left: 32 };

  const sliceTooltip = ({ slice }) => {
    return (
      <CustomTooltip
        slice={slice}
        temperatureData={temperatureData}
        humidityData={humidityData}
      />
    );
  };

  const handleChangeSite = (e) => {
    setSelectedSiteChart(e.target.value);
    setTemperatureData([]);
    setHumidityData([]);
  };

  const handleChangeDevice = (e) => {
    setSelectedDevice(e.target.value);
  };

  return (
    <BasicCard
      sx={{
        width: "100%",
        fontFamily: "NotoSans-Medium",
        boxShadow: "unset",
        marginTop: "24px",
        paddingLeft: "16px",
        paddingTop: "16px",
        marginRight: "24px",
        paddingBottom: "16px",
        paddingRight: "16px",
        borderRadius: "8px",
      }}
    >
      <Box>
        <LoadingGif />
        <Box sx={{ display: "inline-flex" }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-Medium",
              fontWeight: 500,
              color: colors.tempHumChartTitle,
            }}
          >
            {intl.formatMessage({ id: "temp_humidity_chart_label" })}
          </Typography>
        </Box>
        <Box sx={{ display: "inline-flex", float: "right" }}>
          <Box sx={{ display: "inline-flex", minWidth: 140 }}>
            <BasicSelectWithoutForm
              menuItems={siteOptions}
              label={intl.formatMessage({ id: "select_site" })}
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage({ id: "validation_required" }),
                },
              }}
              onChange={handleChangeSite}
              value={selectedSiteChart}
              menuItemWidth={"100%"}
              menuPaperSx={{
                width: "250px",
              }}
              SelectDisplayProps={{
                height: "20px",
                display: "flex",
                alignItems: "end",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
              }}
              inputLabelSx={{
                fontFamily: "NotoSans-Regular",
                fontSize: "14px",
                letterSpacing: "0px",
                lineHeight: "20px",
              }}
            />
          </Box>
          <Box
            sx={{ display: "inline-flex", minWidth: 140, marginLeft: "16px" }}
          >
            <BasicSelectWithoutForm
              menuItems={deviceOptions}
              label={intl.formatMessage({ id: "select_device" })}
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage({ id: "validation_required" }),
                },
              }}
              onChange={handleChangeDevice}
              value={selectedDevice}
              menuItemWidth={"100%"}
              SelectDisplayProps={{
                height: "20px",
                display: "flex",
                alignItems: "end",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
              }}
              inputLabelSx={{
                fontFamily: "NotoSans-Regular",
                fontSize: "14px",
                letterSpacing: "0px",
                lineHeight: "20px",
              }}
              menuPaperSx={{
                width: "250px",
              }}
            />
          </Box>
          <Box
            sx={{ display: "inline-flex", minWidth: 140, marginLeft: "16px" }}
          >
            <BasicSelectWithoutForm
              menuItems={timePeriodOptions}
              label={intl.formatMessage({ id: "select_period" })}
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage({ id: "validation_required" }),
                },
              }}
              onChange={({ target }) => {
                setTimePeriod(target.value);
              }}
              value={timePeriod}
              menuItemWidth={"100%"}
              SelectDisplayProps={{
                height: "20px",
                display: "flex",
                alignItems: "end",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
              }}
              inputLabelSx={{
                fontFamily: "NotoSans-Regular",
                fontSize: "14px",
                letterSpacing: "0px",
                lineHeight: "20px",
              }}
              menuPaperSx={{
                width: "250px",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ position: "relative", zIndex: 1, marginTop: "24px" }}>
        <Box sx={{ height: 367, position: "relative", zIndex: 0 }}>
          <BasicLineChart
            data={temperatureData}
            colors={[colors.chartRed]}
            legends={[]}
            axisLeft={axisLeft}
            axisRight={null}
            axisBottom={null}
            enablePoints={false}
            margin={chartMargin}
            yScale={yScale}
            gridYValues={gridYValues}
            enableSlices={humidityData[0]?.data?.length > 0 ? false : "x"}
            sliceTooltip={
              humidityData[0]?.data?.length > 0 ? () => {} : sliceTooltip
            }
          />
        </Box>
        {humidityData[0]?.data?.length > 0 && (
          <Box
            sx={{
              height: 367,
              position: "absolute",
              zIndex: 0,
              width: "100%",
              top: 0,
            }}
          >
            <BasicLineChart
              data={humidityData}
              colors={[colors.chartBlue]}
              legends={[]}
              axisBottom={null}
              axisLeft={null}
              axisRight={axisRight}
              yScale={y2Scale}
              enablePoints={false}
              enableSlices={"x"}
              sliceTooltip={sliceTooltip}
              margin={chartMargin}
              gridYValues={gridYValues2}
            />
          </Box>
        )}
        <CustomChartFooter />
      </Box>
    </BasicCard>
  );
};

export default TemperatureHumidityChart;
