import { useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import {
  DarkModeContext,
  AddIcon,
  BasicCard,
} from "@datwyler/shared-components";

const AddWidgetButton = (props: any) => {
  const { handleAddWidget } = props;
  const intl = useIntl();
  const { darkMode, colors }: any = useContext(DarkModeContext);

  const cardStyle = {
    padding: 0,
    color: "white",
    width: 144,
    height: 104,
    border: "2px dashed " + colors.addWidgetBtnBorder,
    boxShadow: "none",
    boxSizing: "border-box",
    cursor: "pointer",
    backgroundColor: colors.addWidgetBtnBg,
    borderRadius: "8px",
    ":hover": {
      backgroundColor: colors.addWidgetBtnBgHover,
    },
  };

  return (
    <BasicCard onClick={handleAddWidget} sx={cardStyle}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 20,
            paddingBottom: 10,
          }}
        >
          <Box
            style={{
              width: 40,
              height: 40,
              borderRadius: 20,
              backgroundColor: colors.datwylerTeal,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: darkMode ? 1.0 : 0.6,
            }}
          >
            <AddIcon />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            fontFamily: "NotoSans-Regular",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.widgetFont,
          }}
        >
          <Typography
            style={{
              fontFamily: "NotoSans-Regular",
              fontSize: intl.locale === "de-CH" ? 14 : 16,
            }}
          >
            {intl.formatMessage({ id: "add_widget" })}
          </Typography>
        </Grid>
      </Grid>
    </BasicCard>
  );
};

export default AddWidgetButton;
