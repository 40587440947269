import { useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LineIcon from "./LineIcon";
import { PADDINGLEFT } from "../../constants";
import { useIntl } from "react-intl";
import {
  getContentWidth,
  useResizeListener,
  useNavBarWidthListener,
  useScrollBarListener,
  DarkModeContext,
} from "@datwyler/shared-components";

const CustomChartFooter = () => {
  const TEMPERATURE_LABEL_WIDTH = 100;
  const HUMIDITY_LABEL_WIDTH = 60;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const getMiddleLabelWidth = () => {
    return (
      getContentWidth() -
      PADDINGLEFT -
      TEMPERATURE_LABEL_WIDTH -
      HUMIDITY_LABEL_WIDTH -
      32 -
      24
    );
  };

  const [middleLabelWIdth, setMiddleLabelWidth] = useState(
    getMiddleLabelWidth()
  );

  useResizeListener(() => setMiddleLabelWidth(getMiddleLabelWidth()));
  useNavBarWidthListener(() => setMiddleLabelWidth(getMiddleLabelWidth()));
  useScrollBarListener(() => setMiddleLabelWidth(getMiddleLabelWidth()));

  return (
    <Box>
      <Box sx={{ display: "inline-flex", width: TEMPERATURE_LABEL_WIDTH }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-Medium",
            color: colors.widgetTableFont,
            fontSize: 12,
          }}
        >
          {intl.formatMessage({ id: "temp_humidity_chart_y_label" })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "center",
          width: middleLabelWIdth,
        }}
      >
        <Box>
          <LineIcon color={colors.chartRed} />
        </Box>
        <Box sx={{ marginRight: "56.5px" }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-SemiBold",
              color: colors.chartRed,
              fontSize: 14,
            }}
          >
            {intl.formatMessage({ id: "temperature" })}
          </Typography>
        </Box>
        <Box>
          <LineIcon color={colors.chartBlue} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "NotoSans-SemiBold",
              color: colors.chartBlue,
              fontSize: 14,
            }}
          >
            {intl.formatMessage({ id: "humidity" })}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "inline-flex", width: HUMIDITY_LABEL_WIDTH }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-Medium",
            color: colors.widgetTableFont,
            fontSize: 12,
          }}
        >
          {intl.formatMessage({ id: "temp_humidity_chart_y2_label" })}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomChartFooter;
