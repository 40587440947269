import { Route, Routes } from "react-router-dom";
import Homepage from "./containers/Homepage";
import { CommonStyles } from "@datwyler/shared-components";

const Router = (props: any) => {
  return (
    <Routes>
      <Route
        path="/home"
        element={
          <CommonStyles>
            <Homepage />
          </CommonStyles>
        }
      />
      <Route
        path="/"
        element={
          <CommonStyles>
            <Homepage />
          </CommonStyles>
        }
      />
    </Routes>
  );
};

export default Router;
