import React, { useState } from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  SnackBarProvider,
  useLocale,
  TranslationProvider,
  GraphQLApiProvider,
  DarkModeProvider,
} from "@datwyler/shared-components";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);

  return (
    <React.StrictMode>
      <GraphQLApiProvider uri={props.GRAPHQL_URL}>
        <BrowserRouter>
          <TranslationProvider locale={locale}>
            <DarkModeProvider>
              <SnackBarProvider>
                <App />
              </SnackBarProvider>
            </DarkModeProvider>
          </TranslationProvider>
        </BrowserRouter>
      </GraphQLApiProvider>
    </React.StrictMode>
  );
}
