import Box from "@mui/material/Box";

const LineIcon = (props) => {
  const { color } = props;

  return (
    <Box sx={{ position: "relative", width: "20px" }}>
      <Box
        sx={{
          backgroundColor: color,
          height: "8px",
          width: "8px",
          borderRadius: "4px",
          position: "absolute",
          top: 7,
          left: 5.5,
        }}
      />
      <Box
        sx={{
          border: `2px solid ${color}`,
          width: "15px",
          position: "absolute",
          top: 9,
          padding: 0,
        }}
      />
    </Box>
  );
};

export default LineIcon;
