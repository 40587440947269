import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Memory1Icon,
  DoorIcon,
  SmokeIcon,
  WaterDropIcon,
  Memory2Icon,
  NotificationIcon,
} from "../../Images/Icons";
import {
  CRITICAL_ALARM,
  WATER_LEAK,
  PDU_ALARM,
  ONLINE_DEVICES,
  DOOR_ALARM,
  SMOKE_ALARM,
} from "../../constants";

export const getIconComponent = (iconName, colors) => {
  const bgColor = getIconBgColor(iconName, colors);
  const icon = getIcon(iconName, colors);

  return (
    <Box
      style={{
        display: "inline-flex",
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: bgColor,
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "12px",
        marginTop: "10px",
      }}
    >
      {icon}
    </Box>
  );
};

const getIconBgColor = (type: String, colors: any) => {
  switch (type) {
    case ONLINE_DEVICES:
      return colors.homeIconBgMemory1;
    case DOOR_ALARM:
      return colors.homeIconBgDoor;
    case SMOKE_ALARM:
      return colors.homeIconBgSmoke;
    case WATER_LEAK:
      return colors.homeIconBgWaterDrop;
    case PDU_ALARM:
      return colors.homeIconBgMemory2;
    case CRITICAL_ALARM:
      return colors.homeIconBgNotification;
    default:
      return colors.fontGrey;
  }
};

const getIcon = (type: String, colors) => {
  switch (type) {
    case ONLINE_DEVICES:
      return <Memory1Icon fill={colors.memory1Icon} />;
    case DOOR_ALARM:
      return <DoorIcon fill={colors.doorIcon} />;
    case SMOKE_ALARM:
      return <SmokeIcon fill={colors.smokeIcon} />;
    case WATER_LEAK:
      return <WaterDropIcon fill={colors.waterDropIcon} />;
    case PDU_ALARM:
      return <Memory2Icon fill={colors.memory2Icon} />;
    case CRITICAL_ALARM:
      return <NotificationIcon fill={colors.notificationIcon} />;

    default:
      return null;
  }
};

export const getWidgetDetails = (type, details) => {
  if (type === ONLINE_DEVICES) {
    return (
      <>
        <Typography
          style={{
            fontFamily: "NotoSans-Regular",
            fontSize: 32,
            fontWeight: 500,
            height: 40,
          }}
        >
          {details?.onlineDevices}
        </Typography>
        <Typography
          style={{
            fontFamily: "NotoSans-Regular",
            fontSize: 16,
            display: "flex",
            alignItems: "end",
            lineHeight: "17px",
          }}
        >
          {"/" + details?.totalDevices}
        </Typography>
      </>
    );
  } else {
    return (
      <Typography
        style={{
          fontFamily: "NotoSans-Regular",
          fontSize: 32,
          fontWeight: 500,
          height: 40,
        }}
      >
        {details?.alarmsActivated}
      </Typography>
    );
  }
};
