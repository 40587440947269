export const generateTemperatureHumidityData = (dataFromDb = [], id) => {
  const data = [];

  dataFromDb.map((row) => {
    if (id === "temperature" && (row.name || "").includes("temperature")) {
      data.push({
        x: new Date(row.time * 1000),
        y: row.value,
      });
    } else if (id === "humidity" && (row.name || "").includes("humidity")) {
      data.push({
        x: new Date(row.time * 1000),
        y: row.value,
      });
    }
  });

  return [
    {
      id: id,
      data: data,
    },
  ];
};
